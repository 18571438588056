import React, { useState } from 'react';
import { Container, Typography, Button, TextField, CircularProgress, Paper, Box, LinearProgress } from '@mui/material';
import axios from 'axios';

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [dragging, setDragging] = useState(false);
  const [description, setDescription] = useState('');

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      alert("Lütfen bir PDF dosyası seçin.");
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    setIsLoading(true);
    setDownloadLink('');

    try {
      const response = await axios.post('http://localhost:5000/convert', formData, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(url);
    } catch (error) {
      console.error("Dönüştürme işlemi sırasında bir hata oluştu:", error);
      alert("Dönüştürme işlemi sırasında bir hata oluştu.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', position: 'relative' }}>
      {/* Sol Reklam Alanı */}
      <Box sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        width: '160px',
        backgroundColor: '#f0f0f0',
        textAlign: 'center',
        lineHeight: '90px',
        paddingTop: '20px',
        paddingBottom: '20px',
        boxSizing: 'border-box',
        zIndex: 1000
      }}>
        <div>Reklam Alanı (160x600)</div>
      </Box>

      {/* Sağ Reklam Alanı */}
      <Box sx={{
        position: 'fixed',
        right: 0,
        top: 0,
        bottom: 0,
        width: '160px',
        backgroundColor: '#f0f0f0',
        textAlign: 'center',
        lineHeight: '90px',
        paddingTop: '20px',
        paddingBottom: '20px',
        boxSizing: 'border-box',
        zIndex: 1000
      }}>
        <div>Reklam Alanı (160x600)</div>
      </Box>

      {/* Ana İçerik */}
      <Container maxWidth="md" style={{ marginTop: '50px', marginLeft: '180px', marginRight: '180px' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h4" gutterBottom align="center">
            PDF to Word Converter
          </Typography>

          {isLoading && (
            <Box sx={{ width: '100%', mb: 2 }}>
              <LinearProgress />
            </Box>
          )}

          <Box 
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragEnter}
            onDrop={handleDrop}
            style={{
              border: dragging ? '2px dashed #6200EE' : '2px dashed #CCCCCC',
              padding: '20px',
              borderRadius: '5px',
              textAlign: 'center',
              marginBottom: '20px',
              backgroundColor: dragging ? '#fafafa' : '#ffffff'
            }}
          >
            <input
              accept="application/pdf"
              style={{ display: 'none' }}
              id="upload-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-file">
              <Button variant="contained" component="span" style={{ marginBottom: '20px' }}>
                PDF Dosyası Seç
              </Button>
            </label>
            <Typography variant="body1" color="textSecondary">
              veya dosyayı sürükleyip buraya bırakın
            </Typography>
            <div>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                value={selectedFile ? selectedFile.name : ""}
                placeholder="Seçilen dosya yok"
                style={{ marginTop: '20px' }}
              />
            </div>
          </Box>

          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Dönüştür"}
            </Button>
          </div>

          {downloadLink && (
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <Button
                variant="contained"
                color="secondary"
                href={downloadLink}
                download="converted.docx"
              >
                Word Dosyasını İndir
              </Button>
            </div>
          )}

          {/* Reklam Alanı (Alt) */}
          <Box mt={4}>
            <div style={{ width: '100%', height: '90px', backgroundColor: '#f0f0f0', textAlign: 'center', lineHeight: '90px' }}>
              Reklam Alanı (728x90)
            </div>
          </Box>

          {/* Küçük Açıklama Bölümü */}
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Not
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Tarama olan pdflerde sıkıntı çıkması ihtimali bulunmaktadır. Bununla ilgili geliştirmeler devam etmekte.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default App;
